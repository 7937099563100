export const QuizData = [
  {
    id: 0,
    question: `Bienvenue Docteur. Jouez avec « Cas clinique » et trouvez le bon diagnostic et le traitement le plus adapté pour guérir votre patient !`,
    options: [],
    info: [],
    answer: [],
    infoglobal: [],
    type: "",
    suite: [],
    bg: "medecinzoomeBG",
    reference: [],
    toutref: "",
    chapitre: 0,
  },
  {
    id: 1,
    question: `Ce matin, Madame Laila se présente avec son fils chez le médecin pour une consultation. Amir est un petit garçon de 5 ans. Il est le cadet d'une famille de trois enfants. Le père d’Amir est fumeur et grippé.`,
    options: [],
    info: [],
    answer: [],
    infoglobal: [],
    type: "",
    suite: [],
    bg: "medecinzoome",
    reference: [],
    toutref: "",
    chapitre: 0,
  },
  {
    id: 2,
    question: `Bonjour Docteur, je viens vous voir car Amir ne se sent pas bien. D’habitude il est vif et joyeux. Mais ces 2 derniers jours il tire et se frotte l'oreille gauche et il dit que ça fait mal.`,
    options: [],
    info: [],
    answer: [],
    infoglobal: [],
    type: "",
    suite: [],
    bg: "medecinzoome",
    reference: [],
    toutref: "",
    chapitre: 0,
  },
  {
    id: 3,
    question: `En plus, il est fébrile et n’a pas envie de manger`,
    options: [],
    info: [],
    answer: [],
    infoglobal: [],
    type: "",
    suite: [
      "Le mois dernier, il a eu une otite moyenne aiguë et il a été traité par amoxicilline.",
    ],
    bg: "medecinzoome",
    reference: [],
    toutref: "",
    chapitre: 0,
  },
  {
    id: 4,
    question: `Docteur, en appuyant sur les différents examens, vous pouvez découvrir les résultats correspondants.`,
    options: [
      `TEMPERATURE`,
      `TYMPANOMETRIE`,
      `AUSCULTATION`,
      `OTOSCOPIE PNEUMATIQUE`,
    ],
    info: [
      "L’examen d’Amir note une température à 39°C.",
      `Tympanométrie non disponible.`,
      `Amir n’a pas de râles crépitants et sibilants`,
      `Un épanchement de l’oreille moyenne a été détecté
    par l’otoscopie pneumatique (gonflement
    modéré du tympan)`,
    ],
    answer: [`nfs`, `glycémie à jeun`, `créatinémie`, `urée sanguine`],
    infoglobal: [
      `
    <div class='titre'>Informations </div>
    <div class='info-popup'> 
    <span class='sous_titre1'>Température</span>
    La détermination de la température corporelle est un examen important dans ce cas
    pour déterminer la prise en charge. Les analgésiques sont recommandés pour les
    symptômes de douleur à l'oreille, de fièvre et d'irritabilité.<sup>1</sup>
    </div>
    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>1.</b>Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999.
    </div>
    <div class="lineinfo"></div>`,
      `<div class='titre'>Informations </div>
    <div class='info-popup'> 
    <span class='sous_titre1'>Tympanométrie </span> 
     - mesure de l'immittance acoustique (transfert d'énergie acoustique)
    de l'oreille en fonction de la pression atmosphérique du conduit auditif. Cet examen
    permet de détecter l’épanchement de l'oreille moyenne.<sup>1</sup> </br>
   
    </div>
    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>1.</b>Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999.
    </div>
    <div class="lineinfo"></div>`,
      `<div class='titre'>Informations </div>
    <div class='info-popup'> 
    <span class='sous_titre1'>Auscultation  </span> 
    Selon la théorie des voies respiratoires unifiées, les maladies des voies aériennes
    supérieures et inférieures sont fréquemment comorbides car elles reflètent les
    manifestations d'une seule maladie sous-jacente à différents endroits des voies
    respiratoires, d’où l’intérêt de l’auscultation pulmonaire.<sup>1</sup></br>
    </div>
    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>1.</b>Yii ACA et Al. Allergy: 2018 May.pub ahead of print.
    </div>
    <div class="lineinfo"></div>`,
      `<div class='titre'>Informations </div>
    <div class='info-popup'> 
    <span class='sous_titre1'>L’Otoscopie pneumatique </span> 
    L’Otoscopie pneumatique est l’examen le plus utile pour le
    diagnostic. Elle permet de détecter l’épanchement de l'oreille
    moyenne.<sup>1</sup>
    </div>
    <div class='schema1'> </div>
    <div class='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>1.</b>Venekamp RP, et al. Am Fam Phys 2017;95(2):109–110.
    </div>
    <div class="lineinfo"></div>
    `,
    ],
    type: "click",
    suite: [],
    bg: "infirmiere",
    reference: [],
    toutref: "",
    chapitre: 1,
  },
  {
    id: 5,
    question: `D’après vous docteur, quel est le diagnostic à retenir&nbsp;<span class='interogation'>?</span>`,
    options: [
      `Otite externe diffuse`,
      `Furoncle du conduit`,
      `Otite moyenne aiguë`,
      `Otite moyenne avec effusion`,
    ],
    info: [
      "En fait docteur, la présence d’une otalgie exclut le diagnostic d’une otite externe diffuse",
      "En fait docteur, la présence d’une otalgie exclut le diagnostic d’un furoncle du conduit",
      "Bravo docteur ! En effet la détection d’un épanchement de l’oreille moyenne par otoscopie pneumatique évoque le diagnostic d’otite moyenne aiguë.",
      "En fait docteur, la douleur exclut le diagnostic d’une otite moyenne avec effusion",
    ],

    answer: [`Otite moyenne aiguë`],
    infoglobal: [
      `<div class='titre'>Informations </div>
    
    <div class='info-popup'>
    <span class='sous_titre1'>Otite externe diffuse </span> 
    Diagnostic différentiel de l’otite externe diffuse :
    En présence d’une otalgie, il convient d’éliminer une otite externe diffuse.<sup>1</sup>
    </div>
    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>1.</b> Collège Français d'ORL et de Chirurgie Cervico-faciale. Otites infectieuses de l'adulte et de l'enfant. 2014
    </div><div class="lineinfo"></div>`,
      `<div class='titre'>Informations </div>
    
    <div class='info-popup'>
    <span class='sous_titre1'>Furoncle du conduit </span> 
    Diagnostic différentiel du Furoncle du conduit :
    En présence d’une otalgie, il convient d’éliminer un Furoncle du conduit<sup>1</sup> 
   </div>
    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>1.</b> Collège Français d'ORL et de Chirurgie Cervico-faciale. Otites infectieuses de l'adulte et de l'enfant. 2014
    </div><div class="lineinfo"></div> `,
      `
    <div class='titre'>Informations </div>
    <div class='info-popup'>
    <span class='sous_titre1'>Otite moyenne aiguë</span> 
    L'OMA est plus fréquente chez les jeunes enfants en raison de la trompe d'Eustache
    qui est courte et flexible permettant aux agents pathogènes du nasopharynx de
    pénétrer plus facilement dans l'oreille. La maturité à 7 ans explique la baisse
    d’incidence après cet âge.<sup>1</sup> La détection d'épanchement de l'oreille moyenne par
    otoscopie pneumatique est essentielle pour établir le diagnostic de l'OMA.<sup>2</sup>
    </br>En présence d’une otalgie, il convient d’éliminer une otite externe diffuse ou un
    furoncle du conduit.<sup>3</sup> Les recommandations de l'AAP (American Academy of
    Pediatrics) visent à permettre aux cliniciens de diagnostiquer positivement l’OMA et
    de la distinguer de l’OME (otite moyenne avec effusion, également appelée «oreille
    de colle») et des infections virales, car l’OME ne représente pas un processus
    infectieux aigu qui bénéficie des antibiotiques. </br>
    Ces recommandations de l'AAP (comme
      la plupart des recommandations sur la
      prise en charge des maladies infectieuses)
      visent à éviter l'utilisation inutile
      d'antibiotiques, ce qui augmenterait les
      effets indésirables des médicaments et
      favoriserait le développement d'une
      résistance aux antimicrobiens (RAM).<sup>4</sup>
      Les signes et les symptômes différenciant
      l'OME de l’OMA<sup>5</sup> sont résumés dans le
      tableau.</br>
      La douleur, en particulier, est une
      caractéristique importante de l'OMA et
      peut être sévère.
      <div class='schema3'></div>
      <div class='reference_afterschema3'>
      <div class='sous_titre2'> Références </div> 
      <div class="textreference">
      <b>1.</b>Qureishi A, et al. Infect Drug Res 2014;7:15–24. </br>
      <b>2.</b>Ramakrishnan K, et al. Am Fam Physician 2007;76:1650–1658.</br>
      <b>3.</b>Collège Français d'ORL et de Chirurgie Cervico-faciale. Otites infectieuses de l'adulte et de l'enfant. 2014 </br>
      <b>4.</b>Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999. </br>
      <b>5.</b>Ronald PS. Availableat: http://www.medscape.org/viewarticle/730698. Accesses 23/09/2018
      </div> </div>
      <div class="lineinfo"></div>`,
      `
      <div class='titre'>Informations </div>
    <div class='info-popup'>
    <span class='sous_titre1'>Otite moyenne avec effusion </span> 
     Diagnostic différentiel de l’otite moyenne avec effusion : 
    Les recommandations de l'AAP (American Academy of Pediatrics) visent à permettre
    aux cliniciens de diagnostiquer positivement l’OMA et de la distinguer de l’OME
    (otite moyenne avec effusion, également appelée «oreille de colle») et des infections
    virales, car l’OME ne représente pas un processus infectieux aigu qui bénéficie des
    antibiotiques. Ces recommandations de l'AAP (comme la plupart des
    recommandations sur la prise en charge des maladies infectieuses) visent à éviter
    l'utilisation inutile d'antibiotiques, ce qui augmenterait les effets indésirables des
    médicaments et favoriserait le développement d'une résistance aux antimicrobiens
    (RAM).<sup>1</sup> </br>
    Les signes et les symptômes
    différenciant l'OME de l’OMA<sup>2</sup> sont
    résumés dans le tableau. </br>
    La douleur, en particulier, est une
    caractéristique importante de l'OMA
    et peut être sévère.
    </div>
    <div class='schema2'></div>
    <div class='reference_afterschema'>
    <div class='sous_titre2'> Références </div> 
    <b>1.</b> Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999. </br>
    <b>2.</b> Ronald PS. Available at: http://www.medscape.org/viewarticle/730698. Accesses23/09/2018
    
    </div> <div class="lineinfo"></div>`,
    ],
    type: "qcm",
    suite: [],
    bg: "medecin",
    reference: [],
    toutref: ``,
    chapitre: 2,
  },
  {
    id: 6,
    question: `Quel micro-organisme est prédominant chez les patients présentant une OMA récidivante ou avec échec du traitement<span class='interogation'> ?</span>`,
    options: [
      `<i>Streptococcus pneumoniae</i>`,
      `<i>Haemophilus influenzae</i>`,
      `<i>Moraxella catarrhalis</i>`,
      "<i>Klebsiella pneumoniae</i>",
      `Coronavirus`,
    ],
    info: [
      `En fait docteur, le pneumocoque est l’agent
    pathogène prédominant chez les patients
    présentant une OMA, mais pas une OMA récidivante ou avec échec du
    traitement.`,
      `Oui docteur, <i>H. influenzae</i> est la bactérie
    prédominante chez les patients présentant une
    OMA récidivante et une OMA avec échec du
    traitement.`,
      `En fait docteur, <i>M. Catarrhalis</i> est un germe
    prédominant chez les patients présentant une
    OMA, mais pas une OMA récidivante ou avec
    échec du traitement.`,
      `En fait docteur, <i>K. pneumonaie</i> peut être impliquée
    dans la pneumonie et l’abcès pulmonaires
    d’origine communautaire ou nosocomiale.`,
      `En fait docteur, le Coronavirus est un pathogène
    prédominant chez les patients présentant une
    OMA, mais pas une OMA récidivante ou avec
    échec du traitement.`,
    ],
    answer: [`<i>Haemophilus influenzae</i>`],
    infoglobal: [
      `<div class='titre'>Informations </div>
  
  <div class='info-popup'>
  <span class='sous_titre1'><i>Streptococcus pneumoniae </i></span> 
  L’OMA peut être d’origine bactérienne. Les principaux agents pathogènes de l'OMA
  bactérienne sont : <i>Streptococcus pneumoniae</i>, <i>Haemophilus influenzae</i>, <i>Moraxella
  catarrhalis </i> plus Streptococcus pyogenes (en Amérique latine).</br>
  <i>S. pneumoniae</i> et <i>H. influenzae</i> restent les agents pathogènes bactériens
  prédominants, <i>S. pneumoniae</i> étant la bactérie prédominante dans la majorité des
  cas rapportés chez des patients atteints d'OMA. <i>H. influenzae</i> était la bactérie
  prédominante chez les patients présentant une OME chronique, une OMA
  récidivante et une OMA avec échec du traitement. De nouveaux organismes tels que
  <i>Alloiococcus otitidis</i>, <i>Turicella otitidis</i>, <i>Pseudomonas otitidis</i> et <i>Corynebacterium mucifaciens</i>
  ont été détectés chez des patients atteints d'otite moyenne mais leur implication dans
  la pathogenèse reste incertaine.<sup>1</sup>
  </div>
  <div class ='reference'>
  <div class='sous_titre2'> Références </div> 
  <b>1.</b> Ngo CC, et al. PLoSOne 2016; 11(3): e0150949. doi: 10.1371/journal.pone 0150949

  </div><div class="lineinfo"></div>`,
      `<div class='titre'>Informations </div>
  
  <div class='info-popup'>
  <span class='sous_titre1'><i>Haemophilus influenzae</i> </span> 
  L’OMA peut être d’origine bactérienne. Les principaux agents pathogènes de l'OMA
  bactérienne sont : <i>Streptococcus pneumoniae</i>, <i>Haemophilus influenzae</i>, <i>Moraxella
  catarrhalis </i> plus Streptococcus pyogenes (en Amérique latine).</br>
  <i>S. pneumoniae</i> et <i>H. influenzae</i> restent les agents pathogènes bactériens
  prédominants, <i>S. pneumoniae</i> étant la bactérie prédominante dans la majorité des
  cas rapportés chez des patients atteints d'OMA. <i>H. influenzae</i> était la bactérie
  prédominante chez les patients présentant une OME chronique, une OMA
  récidivante et une OMA avec échec du traitement. De nouveaux organismes tels que
  <i>Alloiococcus otitidis</i>, <i>Turicella otitidis</i>, <i>Pseudomonas otitidis</i> et <i>Corynebacterium mucifaciens</i>
  ont été détectés chez des patients atteints d'otite moyenne mais leur implication dans
  la pathogenèse reste incertaine.<sup>1</sup>
  </div>
  <div class ='reference'>
  <div class='sous_titre2'> Références </div> 
  <b>1.</b> Ngo CC, et al. PLoSOne 2016; 11(3): e0150949. doi: 10.1371/journal.pone 0150949 </div><div class="lineinfo"></div>`,
      `<div class='titre'>Informations </div>
  
  <div class='info-popup'>
  <span class='sous_titre1'><i> Moraxella catarrhalis</i> </span> 
  L’OMA peut être d’origine bactérienne. Les principaux agents pathogènes de l'OMA
  bactérienne sont : <i>Streptococcus pneumoniae</i>, Haemophilus influenzae, <i> Moraxella
  catarrhalis </i> plus Streptococcus pyogenes (en Amérique latine).</br>
 <i> S. pneumoniae </i>et <i>H. influenzae</i> restent les agents pathogènes bactériens
  prédominants, <i>S. pneumoniae</i> étant la bactérie prédominante dans la majorité des
  cas rapportés chez des patients atteints d'OMA. <i>H. influenzae</i> était la bactérie
  prédominante chez les patients présentant une OME chronique, une OMA
  récidivante et une OMA avec échec du traitement. De nouveaux organismes tels que
  <i>Alloiococcus otitidis</i>, <i>Turicella otitidis</i>, <i>Pseudomonas otitidis</i> et <i>Corynebacterium mucifaciens</i>
  ont été détectés chez des patients atteints d'otite moyenne mais leur implication dans
  la pathogenèse reste incertaine.<sup>1</sup>
  </div>
  <div class ='reference'>
  <div class='sous_titre2'> Références </div> 
  <b>1.</b> Ngo CC, et al. PLoSOne 2016; 11(3): e0150949. doi: 10.1371/journal.pone 0150949</div><div class="lineinfo"></div>`,
      `<div class='titre'>Informations </div>
   
  <div class='info-popup'>
  <span class='sous_titre1'><i>Klebsiella pneumoniae</i></span>
  <i>Klebsiella pneumoniae</i> est une entérobactérie, c'est-à-dire un bacille à Gram négatif.
  <i>K. pneumoniae</i> est une cause importante de pneumonie et d'abcès pulmonaires
  d'origine communautaire ou nosocomiale. L'infection du lobe supérieur est plus
  fréquente. Les principaux symptômes sont la fièvre, les frissons, la leucocytose et les
  crachats.<sup>1</sup> </div>
  <div class ='reference'>
  <div class='sous_titre2'> Références </div> 
  <b>1.</b> Fiche Technique Santé-Sécurité : Agents Pathogènes –Klebsiella spp. [en ligne. consulté le 02/04/2020].
https://www.canada.ca/fr/sante-publique/services/biosecurite-biosurete-laboratoire/fiches-techniques-sante-securite-agents-pathog
enes-evaluation-risques/klebsiella.html </div><div class="lineinfo"></div>`,
      `<div class='titre'>Informations </div>
  <div class='info-popup'>
  <span class='sous_titre1'>Coronavirus </span> 
L’OMA peut être d’origine virale. Les virus sont responsables de plus de 90% des
infections. Les virus les plus couramment associés à l'otite moyenne sont : Virus
respiratoire syncytial, adénovirus, rhinovirus et coronavirus.<sup>1</sup>
</div>
<div class ='reference'>
<div class='sous_titre2'> Références </div> 
<b>1.</b> Ngo CC, et al. PLoSOne 2016; 11(3): e0150949. doi: 10.1371/journal.pone 0150949
</div>
<div class="lineinfo"></div>
`,
    ],
    fiche: [],
    type: "qcm",
    suite: [],
    bg: "medecin",
    reference: [],
    toutref: "",
    chapitre: 3,
  },
  {
    id: 7,
    question: `Docteur, quel(s) est (sont) le(s) mécanisme(s) de résistance de l’<i>Haemophilus influenzae</i> à l’amoxicilline<span class='interogation'> ?</span>`,
    options: [
      `Sécrétion de bêtalactamase`,
      `Production de l'enzyme acétyltransférase`,
      `Production de protéines de protection ribosomales`,
      `Efflux par des pompes de l'agent pathogène en dehors du cytoplasme`,
    ],
    info: [
      `C’est vrai docteur, la sécrétion de bêtalactamase est le mécanisme de résistance de l’<i>Haemophilus influenzae</i> à l’amoxicilline`,
      `En fait docteur, la production de l’enzyme acétyltransférase est l’un des mécanismes responsables de la résistance de l’<i>H. influenzae</i> aux chloramphénicol`,
      `Docteur, la production de protéines de protection ribosomales est le plus important mécanisme de résistance de l’<i>H. influenzae</i> aux tétracyclines.`,
      `Docteur, l'efflux par des pompes de l'agent pathogène en dehors du cytoplasme est le mécanisme de résistance de l’<i>H. influenzae</i> aux macrolides`,
    ],
    answer: [`Sécrétion de bêtalactamase`],
    infoglobal: [
      `<div class='titre'>Informations </div>
      
      <div class='info-popup'>
      <span class='sous_titre1'>Sécrétion de bêtalactamase </span> 
      Certaines bactéries responsables d’OMA telles que <i>H. influenzae</i> et <i>M. catarrhalis</i>
      peuvent résister à l’amoxicilline par sécrétion de bêtalactamase.<sup>1</sup> En Tunisie, les
      données de l’antibiorésistance de 2016 montrent un taux d’isolement d’<i>H. influenzae</i>
      producteur de bêtalactamase de l’ordre de 28,2%.<sup>2</sup> En Algérie, ce taux est de l’ordre
      de 25% en 2015.<sup>3</sup> Ces données expliquent l’intérêt de l’amoxicilline/acide
      clavulanique en cas de suspicion d’infection à <i>H. influenzae</i>.
      est un des mécanismes responsables de
      la résistance de <i>H. influenzae</i> aux Chloramphénicol.<sup>4</sup>
      Le mécanisme de résistance le plus important de <i>H. influenzae</i> aux tétracyclines est la
      production de protéines de protection ribosomales.<sup>4</sup>
      La résistance de <i>H. influenzae</i> aux macrolides est due aux pompes qui provoquent
      l'efflux de l'agent du cytoplasme.<sup>5</sup>
      </div>
      <div class ='reference'>
      <div class='sous_titre2'> Références </div>
      <b>1.</b>White A, et al. J Antimicrob Chemother 2004;53(Suppl S1):i3–i20.
<b>2.</b> L’Antibiorésistance en Tunisie -LART. Données de 2016
<b>3.</b> Réseau Algérien de surveillance de la résistance des bactéries aux antibotiques.</br>
<b>4.</b> Abotsiet al., Mechanisms of antibiotic resistance in Haemophilus parainfluenzae. 2017; 32(3) 111-114</br>
<b>5.</b> Christopher et al., Acquired macrolide resistance genes in <i>Haemophilus influenzae</i>? Journal of Antimicrobial Chemotherapy. 2015;
70(8):2234-2236   </div><div class="lineinfo"></div>`,
      `<div class='titre'>Informations </div>

      <div class='info-popup'> 
      <span class='sous_titre1'>La production de l'enzyme acétyltransférase </span> 
      est un des mécanismes responsables de la résistance de <i>H. influenzae</i> au Chloramphénicol.<sup>1</sup>
      </div>
      <div class ='reference'>
      <div class='sous_titre2'> Références </div>
      <b>1.</b> Abotsi et al.,Mechanisms of antibiotic resistance in <i>Haemophilus parainfluenzae</i>.2017;32(3)111-114.
 </div><div class="lineinfo"></div>`,
      `<div class='titre'>Informations </div>

      <div class='info-popup'>
      <span class='sous_titre1'>La production de protéines de protection ribosomales </span> 
Le mécanisme de résistance le plus important de <i>H. influenzae</i> aux tétracyclines est
la production de protéines de protection ribosomales.<sup>1</sup>
</div>
<div class ='reference'>
<div class='sous_titre2'> Références </div>
<b>1.</b> Abotsi et al.,Mechanisms of antibiotic resistance in <i>Haemophilus parainfluenzae</i>.2017;32(3)111-114.
 </div><div class="lineinfo"></div>`,
      `
 <div class='titre'>Informations </div>
<div class='info-popup'>
<span class='sous_titre1'> Efflux par des pompes de l'agent pathogène en dehors du cytoplasme </span>
La résistance de <i>H. influenzae</i> aux macrolides est due aux pompes qui provoquent
l'efflux de l'agent du cytoplasme.<sup>1</sup>
</div>
<div class ='reference'>
<div class='sous_titre2'> Références </div>
<b>1.</b> Christopher et al.,Acquired macrolide resistance genes in <i>Haemophilus influenzae</i> ? Journal of Antimicrobial
Chemotherapy.2015;70(8):2234-2236 </div><div class="lineinfo"></div>`,
    ],
    type: "qcm",
    suite: [],
    bg: "medecin",
    reference: [
      `<div class='titre>Références </div> 
    <div class='reference'>
    <b>1.</b> Agence nationale de sécurité du médicament et des produits de santé (ANSM). Antibiothérapie par voie générale en 
    pratique courante dans les infections respiratoires hautes de l’adulte et l’enfant. Recommandations de bonne pratique, 
    Novembre 2011. </br>
    <b>2.</b> E. Pilly. Maladies Infectieuses et Tropicales. Edition 2014.</br>
    <b>3.</b> E. Pilly. Maladies Infectieuses et Tropicales. Edition 2018.</br>
    <b>4.</b> AlmirallJ et al. Epidemiology of community-acquired pneumonia in adults: a population-based study. EurJ Respir2000,15:757-63.
    </div>`,
      `<div class='titre'>Références </div> 
    <div class='reference'>
    <b>1.</b> Agence nationale de sécurité du médicament et des produits de santé (ANSM). Antibiothérapie par voie générale en 
    pratique courante dans les infections respiratoires hautes de l’adulte et l’enfant. Recommandations de bonne pratique, 
    Novembre 2011.</br>
    <b>2.</b> E. Pilly. Maladies Infectieuses et Tropicales. Edition 2014.</br>
    <b>3.</b> E. Pilly. Maladies Infectieuses et Tropicales. Edition 2018.</br>
    <b>4.</b> AlmirallJ et al. Epidemiology of community-acquired pneumonia in adults: a population-based study. EurJ Respir2000,15:757-63.
    </div>`,
      `<div class='titre'>Références </div> 
    <div class='reference'>
    <b>1.</b> Agence nationale de sécurité du médicament et des produits de santé (ANSM). Antibiothérapie par voie générale en 
    pratique courante dans les infections respiratoires hautes de l’adulte et l’enfant. Recommandations de bonne pratique, 
    Novembre 2011.</br>
    <b>2.</b> E. Pilly. Maladies Infectieuses et Tropicales. Edition 2014.</br>
    <b>3.</b> E. Pilly. Maladies Infectieuses et Tropicales. Edition 2018.</br>
    <b>4.</b> AlmirallJ et al. Epidemiology of community-acquired pneumonia in adults: a population-based study. EurJ Respir2000,15:757-63.
    </div>`,
      `
    <div class='titre'>Références </div> 
    <div class='reference'>
    <b>1.</b> Agence nationale de sécurité du médicament et des produits de santé (ANSM). Antibiothérapie par voie générale en 
    pratique courante dans les infections respiratoires hautes de l’adulte et l’enfant. Recommandations de bonne pratique, 
    Novembre 2011. </br>
    <b>2.</b> E. Pilly. Maladies Infectieuses et Tropicales. Edition 2014.</br>
    <b>3.</b> E. Pilly. Maladies Infectieuses et Tropicales. Edition 2018.</br>
    <b>4.</b> AlmirallJ et al. Epidemiology of community-acquired pneumonia in adults: a population-based study. EurJ Respir2000,15:757-63.
    </div>`,
      `<div class='titre'>Références </div> 
    <div class='reference'> 
    <b>1.</b> Agence nationale de sécurité du médicament et des produits de santé (ANSM). Antibiothérapie par voie générale en 
    pratique courante dans les infections respiratoires hautes de l’adulte et l’enfant. Recommandations de bonne pratique, 
    Novembre 2011. </br>
    <b>2.</b> E. Pilly. Maladies Infectieuses et Tropicales. Edition 2014.</br>
    <b>3.</b> E. Pilly. Maladies Infectieuses et Tropicales. Edition 2018.</br>
    <b>4.</b> AlmirallJ et al. Epidemiology of community-acquired pneumonia in adults: a population-based study. EurJ Respir2000,15:757-63.`,
      `<div class='titre'>Références </div> 
    <div class='reference'>
     <b>1.</b> Agence nationale de sécurité du médicament et des produits de santé (ANSM). Antibiothérapie par voie générale en 
    pratique courante dans les infections respiratoires hautes de l’adulte et l’enfant. Recommandations de bonne pratique, 
    Novembre 2011. </br>
   <b> 2.</b> E. Pilly. Maladies Infectieuses et Tropicales. Edition 2014.</br>
    <b>3.</b> E. Pilly. Maladies Infectieuses et Tropicales. Edition 2018.</br>
    <b>4.</b> AlmirallJ et al. Epidemiology of community-acquired pneumonia in adults: a population-based study. EurJ Respir2000,15:757-63.`,
    ],
    toutref: ``,
    chapitre: 4,
  },
  {
    id: 8,
    question: `Docteur, quelles stratégies thérapeutiques recommanderiez-vous pour traiter Amir<span class='interogation'> ?</span>`,
    options: [
      `Surveillance seulement`,
      `Antibiothérapie`,
      `Analgésiques`,
      `Paracentèse`,
    ],

    info: [
      `En fait docteur, face à une OMA avec des symptômes sévères, un traitement immédiat est nécessaire.`,
      `C’est vrai docteur, les antibiotiques doivent être systématiquement prescrits dans ce cas`,
      `En effet docteur, les analgésiques sont recommandés dans ce cas`,
      `En fait docteur, la paracentèse n’est pas indiquée dans ce cas`,
    ],
    answer: [`Antibiothérapie`, `Analgésiques`],
    infoglobal: [
      `<div class='titre'>Informations </div>
    
    <div class='info-popup'>
    <span class='sous_titre1'>Surveillance seulement </span> 
    Les antibiotiques doivent être systématiquement prescrits aux enfants atteints d'OMA
    âgés de six mois ou plus présentant des signes ou des symptômes sévères comme
    une otalgie modérée ou intense, une otalgie pendant au moins 2 jours ou de la fièvre
    ⩾ 39°C, et aux enfants de moins de deux ans atteints d'OMA bilatérale, quels que
    soient les signes ou symptômes supplémentaires.<sup>1</sup>
    </div>
    <div class ='reference'>
    <div class='sous_titre2'> Références </div>
    <b>1.</b> Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999
    </div><div class="lineinfo"></div>`,
      `<div class='titre'>Informations </div>
     
    <div class='info-popup'>
    <span class='sous_titre1'>Antibiothérapie </span>
    Les antibiotiques doivent être systématiquement prescrits aux enfants atteints d'OMA
    âgés de six mois ou plus présentant des signes ou des symptômes graves comme
    l’otalgie modérée ou sévère, otalgie pendant au moins 2 jours ou de fièvre égale ou
    supérieure à 39°C, et pour les enfants de moins de deux ans atteints d'OMA
    bilatérale, quels que soient les signes ou symptômes supplémentaires.<sup>1</sup>
   </div>
   <div class ='reference'>
   <div class='sous_titre2'> Références </div>
    <b>1.</b>Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999.</div><div class="lineinfo"></div>
    `,
      `<div class='titre'>Informations </div>
    <div class='info-popup'>
    <span class='sous_titre1'>Analgésiques</span>
    Les analgésiques sont recommandés pour les symptômes de douleur à l'oreille, de
    fièvre et d'irritabilité.<sup>1</sup>
    </div>
    <div class ='reference'>
    <div class='sous_titre2'> Références </div>
    <b>1.</b> Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999.
    </div><div class="lineinfo"></div>`,
      `
    <div class='titre'>Informations </div>
    
    <div class='info-popup'>
    <span class='sous_titre1'>paracentèse </span> 
     peut être indiquée dans un intérêt thérapeutique (drainage) chez le
    nourrisson de moins de 3 mois hyperalgique et quand le tympan est fortement
    bombé ou dans un intérêt diagnostique (identification bactérienne et étude de
    sensibilité).<sup>1</sup>
    </div>
    <div class ='reference'>
<div class='sous_titre2'> Références </div>
    <b>1.</b> Otites infectieuses de l’adulte et del’enfant. ECN.PILLY 2018:UE6;N°147
    </div><div class="lineinfo"></div>`,
    ],
    fiche: [
      `Bronchite aiguë`,
      `Pleurésie aiguë`,
      `Pneumonie grippale primaire`,
      "Pneumonie bactérienne",
      `Péricardite`,
    ],
    type: "qcm",
    suite: [
      `Bravo docteur, l’antibiothérapie et les analgésiques sont les stratégies thérapeutiques recommandées dans ce cas`,
    ],
    bg: "medecin",
    reference: [
      `
     <div class='titre'>Références </div> 
    <div class='reference'>
     <b>1.</b> Agence nationale de sécurité du médicament et des produits de santé (ANSM). Antibiothérapie par voie générale en pratique 
     courante dans les infections respiratoires hautes de l’adulte et l’enfant. Recommandations de bonne pratique, Novembre 2011.</br>
     <b>2.</b> Luong Ba K., Harbart S., Carballo S. La procalcitonine : doser ou ne pas doser ? Rev Med Suisse 2013 ; 9 : 1881-85.
     </div>`,
      `
     <div class='titre'>Références </div> 
    <div class='reference'>
     <b>1.</b> Agence nationale de sécurité du médicament et des produits de santé (ANSM). Antibiothérapie par voie générale en pratique 
     courante dans les infections respiratoires hautes de l’adulte et l’enfant. Recommandations de bonne pratique, Novembre 2011.</br>
     <b>2.</b> Luong Ba K., Harbart S., Carballo S. La procalcitonine : doser ou ne pas doser ? Rev Med Suisse 2013 ; 9 : 1881-85.
     </div>`,
      `
     <div class='titre'>Références </div> 
    <div class='reference'>
     <b>1.</b> Agence nationale de sécurité du médicament et des produits de santé (ANSM). Antibiothérapie par voie générale en pratique 
     courante dans les infections respiratoires hautes de l’adulte et l’enfant. Recommandations de bonne pratique, Novembre 2011.</br>
     <b>2.</b> Luong Ba K., Harbart S., Carballo S. La procalcitonine : doser ou ne pas doser ? Rev Med Suisse 2013 ; 9 : 1881-85.`,
      `<div class='titre'>Références </div> 
     <div class='reference'>
     <b>1.</b> Agence nationale de sécurité du médicament et des produits de santé (ANSM). Antibiothérapie par voie générale en pratique 
     courante dans les infections respiratoires hautes de l’adulte et l’enfant. Recommandations de bonne pratique, Novembre 2011.</br>
     <b>2.</b> Luong Ba K., Harbart S., Carballo S. La procalcitonine : doser ou ne pas doser ? Rev Med Suisse 2013 ; 9 : 1881-85.
     </div>`,
      `<div class='titre'>Références </div> 
     <div class='reference'>
     <b>1.</b> Agence nationale de sécurité du médicament et des produits de santé (ANSM). Antibiothérapie par voie générale en pratique 
     courante dans les infections respiratoires hautes de l’adulte et l’enfant. Recommandations de bonne pratique, Novembre 2011.</br>
     <b>2.</b> Luong Ba K., Harbart S., Carballo S. La procalcitonine : doser ou ne pas doser ? Rev Med Suisse 2013 ; 9 : 1881-85.
     </div>`,
    ],
    toutref: `
     <div class='titre'>Informations </div>
    <div class='info-popup'>
     Les antibiotiques doivent être systématiquement prescrits aux enfants atteints d'OMA
      âgés de six mois ou plus présentant des signes ou des symptômes graves comme
      l’otalgie modérée ou sévère, otalgie pendant au moins 2 jours ou de fièvre égale ou
      supérieure à 39°C, et pour les enfants de moins de deux ans atteints d'OMA
      bilatérale, quels que soient les signes ou symptômes supplémentaires.<sup>1</sup></br>
      Les analgésiques sont recommandés pour les symptômes de douleur à l'oreille, de
      fièvre et d'irritabilité.<sup>1</sup></br>
      La paracentèse peut être indiquée dans un intérêt thérapeutique (drainage) chez le
      nourrisson (< 3 mois) hyperalgique et quand le tympan est fortement bombé ou
      dans un intérêt diagnostique (identification bactérienne et étude de sensibilité).<sup>2</sup>
      </div>
      <div class='reference'>
      <div class='sous_titre2'> Références </div>
      
    <b>1.</b>Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999. </br>
    <b>2.</b>Otites infectieuses de l’adulte et de l’enfant. ECN.PILLY 2018:UE6;N°147.

    </div>
    <div class="lineinfo"></div>
   `,
    chapitre: 5,
  },
  {
    id: 9,
    question: `Docteur, en se basant sur le contexte et les données cliniques du patient, votre antibiothérapie de première intention reposera sur :`,
    options: [
      `Amoxicilline/acide clavulanique`,
      `Cefpodoxime`,
      `Céfuroxime`,
      `Azithromycine`,
    ],

    info: [
      `Bravo docteur, en effet l’amoxicilline/acide clavulanique est le traitement approprié pour ce patient`,
      `En fait docteur, vous pouvez prescrire le cefpodoxime s’il y a une allergie à la pénicilline
    (sans allergie aux céphalosporines)`,
      `En fait docteur, vous pouvez prescrire la céfuroxime
    s’il y a une allergie à la pénicilline (sans allergie
    aux céphalosporines)`,
      `En fait docteur, l’azithromycine ne diffuse pas adéquatement dans le liquide de l'oreille moyenne.`,
    ],
    answer: [`Amoxicilline/acide clavulanique`],
    infoglobal: [
      `
    <div class='titre'>Informations </div>
    
    <div class='info-popup'>
    <span class='sous_titre1'>Antibiothérapie </span> 
    Amoxicilline/acide clavulanique peut être administré si le patient a déjà reçu de
    l'amoxicilline 30 jours auparavant, s'il présente une conjonctivite ou si une
    couverture est requise pour <i>Haemophilus influenzae</i> ou <i>Moraxella catarrhalis</i>.<sup>1</sup>
    La thérapie alternative (si allergie à la pénicilline) Cefdinir, Céfuroxime,
    Cefpodoxime ou Ceftriaxone. Il est important de noter que les antibiotiques
    alternatifs varient dans leur efficacité contre les agents pathogènes de l’OMA.
    L'efficacité in vitro du Cefpodoxime et du Céfuroxime contre <i>H. influenzae</i> est d'environ
    98%, contre 58% d'efficacité de l'amoxicilline et près de 100% d'efficacité
    d'amoxicilline/acide clavulanique.<sup>1</sup> Il est important que l’antibiotique soit présent
    dans le site de l’infection. Au cours de l 'OMA, la plupart des bactéries pathogènes
    sont observées dans le liquide de l'oreille moyenne (LOM) où se concentrent
    également les antibiotiques β-lactames (par exemple, l'amoxicilline/acide
    clavulanique).</br>
    Les autres antibiotiques, par exemple les
macrolides, tels que l'azithromycine, se
trouvent principalement dans les cellules.
L'éradication efficace des agents pathogènes
par l'amoxicilline/acide clavulanique est le
résultat d'une pénétration rapide dans le
LOM, de sorte qu'il est présent à des
concentrations suffisamment élevées au site
d'infection.<sup>2</sup>
    </div>
    <div class='schema4'></div>
    <div class='reference_afterschema'>
    <div class='sous_titre2'> Références </div>
    <b>1.</b>Lieberthal AS, et al. Pediatrics2013;131(3):e964–e999 </br>
    <b>2.</b>Dagan R, et al. Lancet Infect Dis2002;2:593–604
    </div><div class="lineinfo"></div>`,
      `
    <div class='titre'>Informations </div>
     
    <div class='info-popup'>
    <span class='sous_titre1'>Cefpodoxime </span>
    La thérapie alternative (si allergie à la pénicilline) : Cefdinir, Céfuroxime,
    Cefpodoxime ou Ceftriaxone.
    Il est important de noter que les antibiotiques
    alternatifs varient dans leur efficacité contre
    les agents pathogènes de l’OMA. L'efficacité
    in vitro du Cefpodoxime contre l’<i>H. influenzae</i> est
    d'environ 98%, contre 58% d'efficacité de
    l'amoxicilline et près de 100% d'efficacité de
    l’amoxicilline/acide clavulanique.<sup>1</sup>
    </div>
    <div class='schema4 topschema4'></div>
    <div class='reference_afterschema'>
    <div class='sous_titre2'> Références </div>
    <b>1.</b> Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999.
    </div><div class="lineinfo"></div>
    `,
      `<div class='titre'>Informations </div> 
    <div class='info-popup'>
    <span class='sous_titre1'>Céfuroxime </span>
    La thérapie alternative (si allergie à la pénicilline) : Cefdinir, Céfuroxime,
    Cefpodoxime ou Ceftriaxone.
    Il est important de noter que les antibiotiques
    alternatifs varient dans leur efficacité contre
    les agents pathogènes de l’OMA. L'efficacité
    in vitro du Céfuroxime contre l’<i>H. influenzae</i>
    est d'environ 98%, contre 58% d'efficacité de
    l'amoxicilline et près de 100% d'efficacité de
    l’amoxicilline/acide clavulanique.<sup>1</sup>
    </div>
    <div class='schema4 topschema4'></div>
    <div class='reference_afterschema'>
    <div class='sous_titre2'> Références </div>
    <b>1.</b> Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999.</div>
    <div class="lineinfo"></div>
    `,
      `<div class='titre'>Informations </div>
     
    <div class='info-popup'>
    <span class='sous_titre1'>Azithromycine </span>
    Il est important que l’antibiotique soit présent dans le site de l’infection. Au cours de
    l'OMA, la plupart des bactéries pathogènes sont observées dans le liquide de
    l'oreille moyenne (LOM) où se concentrent également les antibiotiques de la classe
    des bêtalactamines (par exemple, l'amoxicilline/acide clavulanique). </br> Les autres
    antibiotiques, par exemple les macrolides, tels que l'azithromycine, se trouvent
    principalement dans les cellules. L'éradication efficace des agents pathogènes par
    l'amoxicilline/acide clavulanique est le résultat d'une pénétration rapide dans le
    LOM, de sorte qu'il est présent à des concentrations suffisamment élevées au site
    d'infection.<sup>1</sup>
    </div>
    <div class='reference'>
    <div class='sous_titre2'> Références </div>
    <b>1.</b> Dagan R,et al. Lancet Infect Dis 2002;2:593–604
    </div><div class="lineinfo"></div>`,
    ],
    type: "qcm",
    suite: [],
    bg: "medecin",
    toutref: ``,
    chapitre: 6,
  },
  {
    id: 10,
    question: `Docteur, quelle durée de traitement préconiseriez vous pour Amir <span class='interogation'>?</span>`,
    options: ["10 jours", "7 jours", "5-7 jours"],
    info: [
      `Docteur, cette durée n’est pas la durée appropriée pour Amir car il a 5 ans`,
      `Bravo docteur. Cette durée de traitement est appropriée pour Amir`,
      `Docteur, cette durée n’est pas la durée appropriée pour Amir car il a 5 ans`,
    ],
    answer: [`7 jours`],
    infoglobal: [
      `<div class='titre'>Informations </div>
      
      <div class='info-popup'>
      <span class='sous_titre1'>Durée de traitement : </span> 
      Selon l’AAP (American Academy of Pediatrics) et l’AAFP (American Academy of
        Family Physicians) la durée du traitement de l’OMA par l’amoxicilline/acide
        clavulanique est de 10 jours chez les enfants de moins de 2 ans.<sup>1</sup>
      </div>
    <div class='reference'>
    <div class='sous_titre2'>Références </div> 
    <b>1.</b>Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999.
      
      </div>
      <div class="lineinfo"></div>
      `,
      `<div class='titre'>Informations </div>
      
      <div class='info-popup'>
      <span class='sous_titre1'>Durée de traitement : </span> 
      Selon l’AAP (American Academy of Pediatrics) et l’AAFP (American Academy of
        Family Physicians) la durée du traitement de l’OMA par l’amoxicilline/acide
        clavulanique est de 10 jours, 5-7 jours et 7 jours chez les enfants âgés de de moins
        de 2 ans, 6 ans et plus et 2 à 5 ans respectivement.<sup>1</sup>
        Une stratégie potentielle pour réduire le risque de résistance aux antimicrobiens
        consiste à limiter la durée du traitement antibiotique. D'anciens essais cliniques
        comparant un traitement de durée réduite à un traitement de durée standard chez
        les enfants atteints d'otite moyenne aiguë ont montré soit aucune différence dans les
        résultats, soit des différences minimes qui favorisent un traitement de durée
        standard. </br>
        Cette étude clinique a concerné 520 enfants âgés de 6 à 23 mois, atteints d’OMA
        et recevant de l’amoxicilline/acide clavulanique soit pour une durée standard de 10
        jours, soit pour une durée réduite de 5 jours suivie d’un placebo pendant 5 jours.
        Le résultat a été mesuré en termes de : réponse clinique (signes et réponse
        symptomatique) récurrence et colonisation nasopharyngée. Les résultats ont montré
        que chez les enfants de 6 à 23 mois atteints d'otite moyenne aiguë, un traitement
        antimicrobien de durée réduite donnait des résultats moins favorables que le
        traitement de durée standard ; En outre, ni le taux d'effets indésirables ni le taux
        d'apparition d'une résistance aux antimicrobiens n'étaient plus bas avec le schéma
        thérapeutique le plus court.<sup>2</suup>.
        </div>
      
    <div class='reference'>
    <div class='sous_titre2'>Références </div> 
    <b>1.</b> Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999.</br>
    <b>2.</b> Hoberman A,et al. N Engl J Med 2016;375:2446–2456.57
      </div>
      <div class="lineinfo"></div>
      `,
      `<div class='titre'>Informations </div>
      <div class='info-popup'>
      <span class='sous_titre1'>Durée de traitement : </span> 
      Selon l’AAP (American Academy of Pediatrics) et l’AAFP (American Academy of
        Family Physicians) la durée du traitement de l’OMA par l’amoxicilline/acide
        clavulanique est de 5-7 jours chez les enfants de 6 ans et plus.<sup>1</sup>
      </div>
    <div class='reference'>
    <div class='sous_titre2'>Références </div> 
    <b>1.</b> Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999.
      </div>
      <div class="lineinfo"></div>`,
    ],
    type: "qcm",
    suite: [],
    bg: "medecin",
    reference: [
      `<div class='titre'>Références </div> 
    <div class='reference'>
    <b>1.</b> Agence nationale de sécurité du médicament et des produits de santé (ANSM). Antibiothérapie par voie générale en pratique 
    courante dans les infections respiratoires hautes de l’adulte et l’enfant. Recommandations de bonne pratique, Novembre 2011.</br>
    <b>2.</b> Luong Ba K., Harbart S., Carballo S. La procalcitonine : doser ou ne pas doser ? Rev Med Suisse 2013 ; 9 : 1881-85.
    </div>`,
    ],
    toutref: `<div class='titre'>Références </div> 
    <div class='reference'>
    <b>1.</b> Agence nationale de sécurité du médicament et des produits de santé (ANSM). Antibiothérapie par voie générale en pratique 
    courante dans les infections respiratoires hautes de l’adulte et l’enfant. Recommandations de bonne pratique, Novembre 2011.</br>
    <b>2.</b> Luong Ba K., Harbart S., Carballo S. La procalcitonine : doser ou ne pas doser ? Rev Med Suisse 2013 ; 9 : 1881-85.
    </div>`,
    chapitre: 6,
  },
  {
    id: 11,
    question: [
      `Docteur, quels conseils recommanderiez-vous pour Amir et sa maman ?`,
    ],
    options: [
      `Participer à des activités sportives avec des enfants`,
      `Eviter d'aller à l'école ou au jardin d'enfants`,
      `Eviter l’exposition à la fumée de cigarette`,
      `Terminer l’antibiothérapie même si Amir se sent mieux et suivre scrupuleusement la prescrition médicale`,
    ],
    info: [
      `En fait docteur, il est recommandé d’éviter ça pour réduire l’incidence des infections des voies respiratoires supérieures`,
      `C’est vrai docteur, cela est recommandé pour réduire l’incidence des infections des voies respiratoires supérieures`,
      `Bravo docteur, il faut encourager la mère à s’assurer qu’Amir n’est pas exposé à la fumée de
    cigarette de son père`,
      `En effet, il est important de prendre l’antibiotique
    tel que prescrit par le médecin`,
    ],
    answer: [
      `Eviter d'aller à l'école ou au jardin d'enfants`,
      `Eviter l’exposition à la fumée de cigarette`,
      `Terminer l’antibiothérapie même si Amir se sent mieux et suivre scrupuleusement la prescrition médicale`,
    ],
    infoglobal: [
      `<div class='titre'>Informations </div> 
    <div class='info-popup'>
    <span class='sous_titre1'>Participer à des activités sportives avec des enfants </span>
    Les guidelines mentionnent que, pendant l'enfance et la petite enfance, réduire
    l'incidence des infections des voies respiratoires supérieures en modifiant les
    habitudes de fréquentation des centres de la petite enfance peut réduire
    considérablement l'incidence des OMA récurrentes.<sup>1</sup>
    </div>
  <div class='reference'>
  <div class='sous_titre2'>Références </div> 
  <b>1.</b> Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999.
    </div><div class="lineinfo"></div>`,
      `<div class='titre'>Informations </div>
    
    <div class='info-popup'>
    <span class='sous_titre1'>Réduire les visites pour les centres de la petite enfance </span> 
    Les guidelines mentionnent que, pendant l'enfance et la petite enfance, réduire
    l'incidence des infections des voies respiratoires supérieures en modifiant les
    habitudes de fréquentation des centres de la petite enfance peut réduire
    considérablement l'incidence des OMA récurrentes.<sup>1</sup>
    </div>
  <div class='reference'>
  <div class='sous_titre2'>Références </div> 
  <b>1.</b> Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999.
    </div><div class="lineinfo"></div>`,
      `<div class='titre'>Informations </div> 
    <div class='info-popup'>
    <span class='sous_titre1'>Eviter l’exposition à la fumée de cigarette </span>
    Les médecins doivent encourager les parents à s’assurer que leurs enfants ne sont
    pas exposés à la fumée de cigarette.<sup>1</sup>
    </div>
  <div class='reference'>
  <div class='sous_titre2'>Références </div> 
  <b>1.</b> Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999.
    </div><div class="lineinfo"></div>`,
      `<div class='titre'>Informations </div>
    
    <div class='info-popup'>
    <span class='sous_titre1'>Terminer l’antibiothérapie même si Amir se sent mieux et suivre scrupuleusement la prescription médicale </span> 
    A fin d’éviter l’antibiorésistance, il est important de prendre l’antibiotique tel que
    prescrit par le médecin.<sup>1</sup></br>
    Le nouveau Système mondial de l’OMS de surveillance de la résistance aux
    antimicrobiens (GLASS) révèle que la résistance aux antibiotiques est un problème
    très répandu qui touche 500 000 personnes présentant des infections bactériennes
    présumées dans 22 pays.<sup>2</sup></br>
    Le lancement du GLASS permet déjà des progrès dans nombreux de pays. Par
    exemple, la Tunisie a commencé à rassembler des données sur la résistance aux
    antimicrobiens au niveau national.<sup>2</sup>
    </div>
  <div class='reference'>
  <div class='sous_titre2'>Références </div> 
  <b>1.</b> Antibiotic Resistance Questions & Answers. Centers for Disease Control and Prevention. Available at http://www.cdc.gov/getsmart/
antibiotic-use/antibiotic-resistance-faqs. Consultée le 25 Mars 2020.</br>
<b>2.</b> OMS. Niveaux élevés de résistance aux antibiotiques dans le monde. [en ligne. consulté le 06/05/2020].
https://www.who.int/fr/news-room/detail/29-01-2018-high-levels-of-antibiotic-resistance-found-worldwide-new-data-shows
 
    </div>
    <div class="lineinfo"></div>`,
    ],
    type: "qcm",
    suite: [
      `Bravo docteur, ces trois conseils sont recommandés pour ce patient`,
    ],
    bg: "medecin",
    reference: [],
    toutref: `
    <div class='titre'>Informations </div> 
    <div class='info-popup'>
    Les guidelines mentionnent que, pendant l'enfance et la petite enfance, réduire
l'incidence des infections des voies respiratoires supérieures en modifiant les
habitudes de fréquentation des centres de la petite enfance peut réduire
considérablement l'incidence des OMA récurrentes.<sup>1</sup></br>
Les médecins doivent encourager les parents à s’assurer que leurs enfants ne sont
pas exposés à la fumée de cigarette.<sup>1</sup></br>
Afin d’éviter l’antibiorésistance, il est important de prendre l’antibiotique tel que
prescrit par le médecin.<sup>2</sup></br>
Le nouveau Système mondial OMS de surveillance de la résistance aux
antimicrobiens (GLASS) révèle que la résistance aux antibiotiques est un problème
très répandu qui touche 500 000 personnes présentant des infections bactériennes
présumées dans 22 pays.<sup>3</sup></br>
Le lancement du GLASS permet déjà des progrès dans de nombreux pays. Par
exemple, la Tunisie a commencé à rassembler des données sur la résistance aux
antimicrobiens au niveau au national.<sup>3</sup>
</div>
<div class='reference'>
<div class='sous_titre2'>Références </div> 
<b>1.</b>Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999.</br>
<b>2.</b>Antibiotic Resistance Questions & Answers. Centers for Disease Control and Prevention. Available at http://www.cdc.gov/getsmart/
antibiotic-use/antibiotic-resistance-faqs. Consultée le 25 Mars 2020.</br>
<b>3.</b>OMS. Niveaux élevés de résistance aux antibiotiques dans le monde. [en ligne. consulté le 06/05/2020].
https://www.who.int/fr/news-room/detail/29-01-2018-high-levels-of-antibiotic-resistance-found-worldwide-new-data-shows

  </div>
  <div class="lineinfo"></div>
`,
    chapitre: 7,
  },
  {
    id: 12,
    question: [
      `En plus de la radiographie du thorax, le médecin traitant a proposé au patient de faire un bilan biologique de routine.`,
    ],
    options: [],
    info: [],
    answer: [],
    infoglobal: [],
    type: "",
    suite: [],
    bg: "conseil",
    reference: [],
    toutref: "",
    chapitre: 7,
  },
  {
    id: 13,
    question: `Une semaine plus tard, Amir et sa maman sont
    revenus pour la visite de contrôle. Amir s’est bien
    rétabli, il n’a plus de fièvre et l’examen de son
    tympan est normal.`,
    options: [],
    info: [],
    answer: [],
    infoglobal: [],
    type: "",
    suite: [],
    bg: "medecin",
    reference: [],
    toutref: "",
    chapitre: 8,
  },
  {
    id: 14,
    question: `Docteur, quelles sont les mesures à prendre pour prévenir la survenue d’OMA <span class='interogation'>?</span>`,
    options: [
      `Vaccination anti-pneumococcique`,
      `Vaccination anti-grippale pendant la saison des maladies respiratoires`,
      `Vaccination BCG`,
      `Vaccination DTC`,
    ],
    info: [
      `Oui, les médecins devraient recommander le
  vaccin conjugué contre le pneumocoque à tous les
  enfants. Ce vaccin peut réduire le risque d’OMA`,
      `Oui, il est recommandé de vacciner contre la
  grippe, tous les ans, pendant la saison des
  maladies respiratoires. Ce vaccin peut réduire le
  risque d’OMA`,
      `Docteur, la vaccination BCG est indiquée pour
  prévenir la tuberculose, elle n’a aucune place dans
  la prévention de l’OMA`,
      `Docteur, la vaccination DTC est indiquée pour
  prévenir la diphtérie, le tétanos et la coqueluche,
  elle n’a aucune place dans la prévention de l’OMA`,
    ],
    answer: [
      `Vaccination anti-pneumococcique`,
      `Vaccination anti-grippale pendant la saison des maladies respiratoires`,
    ],
    infoglobal: [
      ` <div class='titre'>Informations </div>
     
    <div class='info-popup'>  
    <span class='sous_titre1'>Vaccination anti-pneumococcique </span> 
    Les médecins devraient recommander le vaccin conjugué contre le pneumocoque à
    tous les enfants.<sup>1</sup> Une méta-analyse de cinq études portant sur l'OMA en tant que
    critère d'évaluation a déterminé qu'il y avait une réduction de 29% de l'OMA causée
    par tous les sérotypes de pneumocoque chez les enfants ayant reçu le PCV7 avant
    l'âge de 24 mois.<sup>2</sup> Des études observationnelles ont montré que le nombre de visites
    au cabinet médical pour otite était réduit de 40% en comparant les années
    précédant et après l’introduction du PCV7.<sup>1</sup>
   </div>
     <div class='reference'>
     <div class='sous_titre2'>Références </div> 
    <b>1.</b> Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999
    
    </div>
    <div class="lineinfo"></div>
    `,
      `<div class='titre'>Informations </div>
    
    <div class='info-popup'>  
    <span class='sous_titre1'>Vaccination anti-grippale pendant la saison des maladies respiratoires </span>  
    Les cliniciens devraient recommander le vaccin annuel contre la grippe à tous les
    enfants. De nombreuses études ont démontré une efficacité de 30 à 55% du vaccin
    antigrippal dans la prévention de l'OMA pendant la saison des maladies
    respiratoires.<sup>1</sup>
    </div>
     <div class='reference'>
     <div class='sous_titre2'>Références </div> 
     <b>1.</b> Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999. </div> </div>
     <div class="lineinfo"></div>
    `,
      `<div class='titre'>Informations </div>
    
    <div class='info-popup'>
    <span class='sous_titre1'>Vaccination BCG</span> 
     est le seul vaccin existant pour prévenir la tuberculose, préparé à
    partir du bacille de Calmette-Guérin (BCG).<sup>1</sup>
    </div>
     <div class='reference'>
     <div class='sous_titre2'>Références </div> 
     <b>1.</b> OMS. Questions et réponses sur les vaccins antituberculeux. [en ligne. consulté le 02/04/2020]
https://www.who.int/tb/vaccinesfaqs/fr/
    </div>
    <div class="lineinfo"></div>
    `,
      `<div class='titre'>Informations </div>
    
    <div class='info-popup'>
    <span class='sous_titre1'>Vaccination DTC</span> 
     peut prévenir la diphtérie, le tétanos et la coqueluche.<sup>1</sup>
    </div>
     <div class='reference'>
     <div class='sous_titre2'>Références </div> 
     <b>1.</b> CDC. DTaP (Diphtheria, Tetanus, Pertussis) Vaccine: What you need to know. [en ligne. consulté le 02/04/2020].
https://www.cdc.gov/vaccines/hcp/vis/vis-statements/dtap.html
     </div>
     <div class="lineinfo"></div>`,
    ],
    type: "qcm",
    suite: [
      `Bravo docteur, les médecins devraient recommander ces deux vaccins.`,
    ],
    bg: "medecin",
    reference: [],
    toutref: `
  <div class='titre'>Informations </div>
  <div class='info-popup'> 
  Les médecins devraient recommander le vaccin conjugué contre le pneumocoque à
  tous les enfants.<sup>1</sup> Une méta-analyse de cinq études portant sur l'OMA en tant que
  critère d'évaluation a déterminé qu'il y avait une réduction de 29% de l'OMA causée
  par tous les sérotypes de pneumocoque chez les enfants ayant reçu le PCV7 avant
  l'âge de 24 mois.<sup>2</sup> Des études observationnelles ont montré que le nombre de visites
  au cabinet médical pour otite était réduit de 40% en comparant les années
  précédant et après l’introduction du PCV7.<sup>1</sup></br>
  Les cliniciens devraient recommander le vaccin annuel contre la grippe à tous les
  enfants. De nombreuses études ont démontré une efficacité de 30 à 55% du vaccin
  antigrippal dans la prévention de l'OMA pendant la saison des maladies
  respiratoires.<sup>1</sup></br>
  Le vaccin BCG est le seul vaccin existant pour prévenir la tuberculose, préparé à
partir du bacille de Calmette-Guérin(BCG).<sup>2</sup></br>
Le vaccin DTC peut prévenir la diphtérie, le tétanos et la coqueluche.<sup>3</sup>
 </div>
  <div class='reference'>
  <div class='sous_titre2'>Références</div>
  <b>1.</b>Lieberthal AS, et al. Pediatrics 2013;131(3):e964–e999.</br>
  <b>2.</b>OMS.Questions et réponses sur les vaccins antituberculeux. [en ligne. consulté le 02/04/2020].https://www.who.int/tb/vaccinesfaqs/fr/ </br>
  <b>3.</b>CDC.DTaP(Diphtheria,Tetanus,Pertussis)Vaccine:What you need to know. [en ligne .consulté le
  02/04/2020].https://www.cdc.gov/vaccines/hcp/vis/vis-statements/dtap.html.
  </div>`,
    chapitre: 8,
  },
  {
    id: 15,
    question: `Les recommandations internationales sur la prise
  en charge des maladies infectieuses visent à éviter
  l'utilisation inutile d'antibiotiques, ce qui
  augmenterait les effets indésirables des
  médicaments et augmenterait le risque
  d'apparition d'une antibiorésistance.<sup>1</sup>`,

    options: [],
    info: [],
    answer: [],
    infoglobal: [],

    type: "",
    suite: [
      `De ce fait, lors de la prescription d’un antibiotique,
    il est important de prendre en considération ces
    principes :<sup>1-3</sup> </br>
    <li class='textli'>Maximiser l’éradication bactérienne</li>
    <li class='textli'> Choisir un antibiotique qui reflète le profil local de
    sensibilité aux antibiotiques </li>
    <li class='textli'>Choisir les antibiotiques et les schémas
    posologiques les plus efficaces </li>
    <li class='textli'>Optimiser le rapport coût-efficacité </li>
    <li class='textli'>Prescrire empiriquement mais intelligemment </li>
    <li class='textli'>Encourager l'observance du patient.`,
      `L'antibiorésistance est un problème de santé
    publique majeur. Si nous n'agissons pas, d'ici 2050,
    elle deviendra l’une des premières causes de
    mortalité dans le monde.<sup>1</sup>`,
      `Bravo docteur ! Vous avez élucidé le cas de Amir !`,
    ],
    bg: "medecin",
    reference: [
      `
  <div class='titre titre-ref'>Références </div>
  <div class='reference'> 
  <b>1.</b>Lieberthal AS, et al. Pediatrics2013;131(3):e964–e999
  </div>
  <div class='lineinfo'></div>`,
      `
  <div class='titre titre-ref'>Références </div>
  <div class='reference'> 
  <b>1.</b> Ball P, et al. J AntimicrobChemother2002;49:31–40.</br>
  <b>2.</b> Ball P, et al. Int J AntimicrobAg 2007;30S:S139–S141.</br>
  <b>3.</b> Levy-Hara G, et al. Front Microbiol2011;2:230.</br>
  </div>
  <div class='lineinfo'></div>`,
      `
  <div class='titre titre-ref'>Références </div>
  <div class='reference'> 
  <b>1.</b>KrakerME, StewardsonAJ et HarbarthS. Will 10 Million People Die a Year due to Antimicrobial Resistance by 2050? PLoS Med. 2016 Nov; 13(11): e1002184.
  </div>
  <div class='lineinfo'></div>`,
    ],
    toutref: ``,
    chapitre: 8,
  },
]

// fiche button contain

export const FicheData = [
  {
    id: 0,
    title: "Présentation du cas",
    fiche: "<li>5 ans </li><li>Père fumeur et grippé </li>",
    qid: 1,
  },
  {
    id: 1,
    title: "Présentation du cas",
    fiche: "<li>Douleur à l’oreille gauche </li>",

    qid: 2,
  },
  {
    id: 2,
    title: "Présentation du cas",
    fiche: "<li>Fébrile, manque d’appétit</li>",
    suitefiche: [
      "<li>Antécédent d’OMA</li>",
      "<li>traitée par Amoxicilline</li>",
    ],
    qid: 3,
  },

  {
    id: 3,
    title: "Examens cliniques",
    fiche: "",
    suitefiche: [
      "<li>T=39°C </li>",
      "",
      "<li>Absence de râles crépitants et sibilants</li> ",
      "<li>Un épanchement de l’oreille moyenne</li><li>Gonflement modéré du tympan</li> ",
    ],
    qid: 4,
  },

  {
    id: 4,
    title: "Examens cliniques",
    fiche: "<span class='fiche4_gris'>Otite moyenne aiguë</span>",
    qid: 5,
  },

  {
    id: 5,
    title: "Examens complémentaires",
    fiche: "<li>Bactéries responsables : ",
    qid: 6,
  },

  {
    id: 6,
    title: "Examens complémentaires",
    fiche: "<i>Haemophilus influenzae </i> </li>",
    suitefiche: [],
    qid: 6,
  },

  {
    id: 7,
    title: "Examens complémentaires",
    fiche: "<li>Mécanisme de résistance : ",
    qid: 7,
  },

  {
    id: 8,
    title: "Examens complémentaires",
    fiche: "Sécrétion de bêta lactamase </li>",
    qid: 7,
  },

  {
    id: 9,
    title: "Prise en charge",
    fiche: "",
    suitefiche: ["", "<li>Antibiothérapie </li>", "<li>Analgésiques </li>", ""],
    qid: 18,
  },
  {
    id: 10,
    title: "Prise en charge",
    fiche: "<li>Antibiothérapie : ",
    qid: 20,
  },
  {
    id: 11,
    title: "Prise en charge",
    fiche: "Amoxicilline/acide clavulanique </li>",
    qid: 21,
  },
  {
    id: 12,
    title: "Prise en charge",
    fiche: "<li>Durée du traitement : ",
    qid: 25,
  },
  {
    id: 13,
    title: "Prise en charge",
    fiche: "7 jours</li>",
    qid: 25,
  },
  {
    id: 14,
    title: "Prise en charge",
    fiche: "<li>Prévention : ",
    qid: 25,
  },
  {
    id: 15,
    title: "Prise en charge",
    fiche: ", ",
    suitefiche: [
      "Vaccination anti-pnaumococcique",
      "Vaccination antigrippal",
      "",
      "",
    ],
    qid: 25,
  },
]
