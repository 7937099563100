import React, {Component} from 'react';
import {QuizData} from './QuizData';
import Questions from './Questions';
import Next from './Next';
import Fiche from './Fiche';
import Sound from './Sound';
import Menu from './Menu';
import Chapitrefin from './Chapitrefin';
import {UserContext} from './Nextcontext';
import Chapitre1 from './Chapitre1';
import Info from './Info';
import Ref from './Ref';


class Check extends Component {
    constructor(props) {
      super(props);
      this.background= null;
      this.state = {
        questionId:1,
        question: '',
        options: [],
        answer: '',
        answers:[],
        info:[],
        infoglobal:[],
        suite:[],
        questionsuite:"",
        type:'',
        incrementation:0,
        bg:'',
        reference:[],
        comptinfo:-1,
        cmpt:-1,
        toutref:"",
        chapitre:0,
        splash_screen:true,
        progress: 0,

  
      };

      this.gonext = this.nextQuestionHandler.bind(this);

    }

    /**********load question with options*********** */
    LoadQuiz = () => {
        const {questionId}= this.state;
        this.setState(()=> {
            return{
                question:QuizData[questionId].question,
                options:QuizData[questionId].options,
                answers:QuizData[questionId].answer,
                info:QuizData[questionId].info,
                infoglobal:QuizData[questionId].infoglobal,
                suite:QuizData[questionId].suite,
                type:QuizData[questionId].type,
                bg:QuizData[questionId].bg,
                reference:QuizData[questionId].reference,
                toutref:QuizData[questionId].toutref,
                chapitre:QuizData[questionId].chapitre,

            };
  
        })
    }
    

    componentWillMount(){
        this.LoadQuiz();
        this.changebg()
    };

  /**********change background*********** */

    changebg(){
      if(this.state.bg=='medecin')
        return('medecin image')

        else if(this.state.bg=='chaise')
        return('chaise image')

        else if(this.state.bg=='docteur')
        return('docteur image')

        else if(this.state.bg=='infirmier')
        return('infirmier image')

        else if(this.state.bg=='infirmiere')
        return('infirmiere image')
        
        else if(this.state.bg=='medecinzoome')
        return('medecinzoome image')

        else if(this.state.bg=='patient')
        return('patient image')
        else if(this.state.bg=='conseil')
        return('conseil image')
        else if(this.state.bg=='medecinzoomeBG')
        return('medecinzoomeBG image')
        
    };
  /**********change background*********** */
    nextQuestionHandler=()=> {
        const{questionId}=this.state;
        this.setState({
          questionId:questionId+1,
          answer:'',
          check : false,
          incrementation:0,    
        });
        this.setState(prevState => ({
          i: 1,   
       }));


    };
      
      componentDidUpdate(prevProp,prevState){
      const {questionId} = this.state;
        if(this.state.questionId !== prevState.questionId){
            this.setState(()=> {
                return{
                    disabled:true,
                    question:QuizData[questionId].question,
                    options:QuizData[questionId].options,
                    answers:QuizData[questionId].answer,
                    info:QuizData[questionId].info,
                    infoglobal:QuizData[questionId].infoglobal,
                    type:QuizData[questionId].type,
                    suite:QuizData[questionId].suite,
                    bg:QuizData[questionId].bg,
                    reference:QuizData[questionId].reference,
                    toutref:QuizData[questionId].toutref,
                    chapitre:QuizData[questionId].chapitre,
                    comptinfo:-1
                };
            }) 
        }
        if(this.state.questionId!=prevState.questionId && this.state.questionId==12){
         
          var classes= ['ecoleTexteandimage','cigareTextandimage','medicamentTexteandimage','next']
          classes.forEach(function(element,i,x) {
            
                    setTimeout(() => {   
                        
              var s = document.getElementsByClassName(element);
              s[0].classList.add('fadin_animation');
     

              },(1000*(i)))

          })
        }
      } 
      componentDidMount() {
        var src = [
          "/static/media/splash_screen-01.1adc4ce7.png",
          "/static/media/20_11_25_serious-game_cas-clinique_OMA_Mother_and_son_v2.40e32005.gif",
          "/static/media/sound.fe11cfec.png",
          "/static/media/fiche.680983eb.png",
          "/static/media/20_11_25_serious-game_cas-clinique_OMA_Nurse_v2.28f50867.gif",
          "/static/media/info.a3dc98a0.png",
          "/static/media/20_11_25_serious-game_cas-clinique_OMA_Doctor_v2.3157e2f0.gif",
          "/static/media/tableau.53bd3353.png",
          "/static/media/element72-06-min.c34e56b1.png",
          "/static/media/ecole.3203ea74.png",
          "/static/media/cigare.fd8e81b0.png",
          "/static/media/medicament.7b58950d.png",
          "/static/media/ref.b0fdcc3c.png",
          "/static/media/schema1.07241896.png",
          "/static/media/schema2.5e1e8bc1.png",
          "/static/media/schema3.5e1e8bc1.png",
          "/static/media/schema4.57ecf722.png",
          "/static/media/ref.b0fdcc3c.png",
          "/static/media/ref.b0fdcc3c.png",
          "/static/media/ref.b0fdcc3c.png",



        ];
              
        const addState =  (val) =>  {
          
          return () =>  { console.log("helooo");this.setState((prevState, props) => ({
                  progress: prevState.progress + val,
                
                }));
          if (this.state.progress == 100){
                  this.setState((prevState, props) => ({
                    splash_screen: false
            
          }));
          }}; 
          
        }
        document.addEventListener("DOMContentLoaded", function(event) {
          // - Code to execute when all DOM content is loaded. 
          // - including fonts, images, etc.
          // this.background=document.querySelectorAll(".image").length;
          
          window.loadIMage(src , 
            addState( ( 1 / src.length) * 100))

          })

          
      }
/*******************return information related with option *********************** */
      infobtn=(cmpt)=>{
        this.setState({
          comptinfo: cmpt, 
          });
      }


/***********reload the quiz************** */

      reload() {
        window.location.reload();
        }

  render() {
    const {comptinfo,incrementation,type,questionsuite,suite,infoglobal,info,question ,answer,options,questionId,answers,reference} = this.state;
    return (
          /**********use context ***********/
          <UserContext.Consumer> 
          { value => ( 

            <div className="App">
{/************************splash screen***************************/} 
               {
                   this.state.splash_screen===true&&
                   <div className="splash image">
                  
                     <div className="myProgress">
                         <div id="myBar" style={{width: this.state.progress + "%"}}>

                           <div class='loading'><span class="load">{ this.state.progress + "%"}</span></div>
                               </div>
                              
                     </div>

                       </div>

                }
{/************************progress indicator  ***************************/} 

                { 
                  this.state.splash_screen==false && 
                  <div>
                  <Menu questionId={this.state.questionId}   chapitre={this.state.chapitre} 
                   /> 
                   
                   </div>
                }
{/************************Chapiter 1 Introduction ***************************/} 

               { (questionId in [0,1,2,3]) && this.state.splash_screen==false &&

                <div className= {this.changebg()}>
                      <div>
                        <Chapitre1  questionId ={questionId}
                                    suite ={suite}
                                    questionsuite={questionsuite}
                                    question ={question}
                                    incrementation={incrementation}
                                    gonext={this.gonext}
                                    chapitre={this.state.chapitre}
                                    splash_screen={this.state.splash_screen}/>

                      </div>

               
                  </div>
                  

                }
{/************************other chapter questions ***************************/} 

                { questionId >= 4 &&  questionId != 15  &&  questionId != 12 &&
                        
                    <div className= {`image ${this.changebg()}`}>  
                        <Questions
                                answer ={answer} 
                                options ={options}
                                question ={question}
                                questionId ={questionId} 
                                answers ={answers} 
                                info={info}
                                infoglobal={infoglobal}
                                suite={suite}
                                type ={type} 
                                incrementation={incrementation}
                                gonext={this.gonext}
                                reference={reference}
                                infobtn={this.infobtn}
                                chapitre={this.state.chapitre}/>  
                  
                        {/************************chapters with Ref ***************************/} 
                        
                   
                        <Info  comptinfo={comptinfo}
                        infoglobal={this.state.infoglobal}
                        index={value.index}
                        toutref={this.state.toutref} 
                        questionId ={questionId} 
                        />
                        <Fiche  questionId={questionId}
                        suite={this.state.suite}
                        question={question}/>  

                    </div >   
                }  
                {/**************************last chapter *************************/} 

                {questionId === 15  &&
                  <div className= {this.changebg()}>
                    <Chapitrefin    questionId ={questionId}
                                    suite ={suite}
                                    questionsuite={questionsuite}
                                    question ={question}
                                    incrementation={incrementation}
                                    reload={this.reload}
                                    gonext={this.gonext}
                                    />
                             
                     
                    <Ref    comptinfo={comptinfo}
                                  reference={this.state.reference}
                                  index={value.index} 
                                  toutref={this.state.toutref}
                                  questionId ={questionId}
                                  suite ={suite} />
                  </div>
                } 

              {/* *** new interface****         */}
              { this.state.questionId=== 12 &&
               <div className= {this.changebg()}>
                 <div className="conseilelements">
                 <div className='ecoleTexteandimage'> 
                      <div className="ecole"> </div>
                      <div className='textConseil textEcole'>Eviter d'aller à l'école ou au jardin d'enfants</div>
                 </div>
                 <div className='cigareTextandimage'> 
                      <div className="cigare"></div>
                      <div className='textConseil textCigare'>Eviter l’exposition à la fumée de cigarette</div>
                 </div>
                 <div className='medicamentTexteandimage'>
                      <div className="medicament"></div>
                      <div  className='textConseil textMedi'>Terminer l’antibiothérapie même si Amir se sent mieux et suivre scrupuleusement la prescription médicale</div>
                    </div>
                 </div>
                       <div  className = "next" style={{display:'none'}}
                       onClick={()=> {this.nextQuestionHandler()} }></div>         
                       </div>    
                                        }
                <Next gonext={this.nextQuestionHandler}
                      questionId={questionId}/>
                  
                  { 
                  this.state.splash_screen==false && 
                  
                   <Sound/>
                  }
                        </div>
                      )}
             
        </UserContext.Consumer>
      );

    }
  }
    
    export default Check;

